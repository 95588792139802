
export const initialValues = {
  primeCusSapCode: "",
  primeCusName: null,
  customerGroupName: null,
  primeCusGstNo: null,
  address: null,
  landmark: null,
  zoneName: null,
  subzoneName: null,
  contactPerson: null,
  contactNumber: null,
  shipToPartyCode: null,
  salesSupportRep: null,
  sapTeamLeader: null,
  teamLeaderName: null,
  zoneHeadName: null,
  salesHeadName: null,
  sapZoneHead: null,
  sapSalesHead: null,
  sapZoneName: null,
  sapSubZoneName: null,
  sapDistrictName: null,
  sapPriceGroupName: null,
  sapCityName: null,
  primeCusEmail: null,
  contactWhatsappNo: null,
  salesOrg: null,
  priceGroupName: null,
  sapCustomerGroupName: null,
  pincode: null,
  incoterms: null,
  distributionChannel: null,
  sapDivision:  5,
  sapCountry: null,
  sapCompany: null,
  regionCode: null,
  contactNo: null,
  file: null,
  districtName: null,
  sapTeamLeaderName: null,
  sapSalesSupportRep: null,
  sapSalesHeadName: null,
  sapZoneHeadName: null,
  latitude: null,
  longitude: null,
  uploadFile: null,
  cityName: null,
  activeStatus: 1,


}
