import { useFormik } from 'formik';
import * as yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { initialValues } from '../constants/constants';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getErrorMessage, getStatus } from '@/store/slices/helpers/helpers';
import { clearMultipleData, fetchData } from '@/store/slices/fetch-slice';
import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { getFormattedPayload, getFormattedResponse } from '@/common/helpers/helpers';
import useGetUserByDivision from '@/common/hooks/useGetUserByDivision';
import useGetSubZoneOptions from '@/common/hooks/useGetSubZoneMaster';

function useCreateEditPrimaryCustomerMaster({ activeEditId, getDistrict, getCity }) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const state = useSelector((store) => store);
  const [image, setImage] = useState('');
  const { getUserList } = useGetUserByDivision();
  const { getSubZoneList } = useGetSubZoneOptions();
  const [alertVisible, setAlertVisible] = useState(false);


  const savePrimaryCustomerMasterStatus = getStatus(state, apiKeys.savePrimaryCustomerMaster, '');
  const getPrimeCustDataFromSapStatus = getStatus(state, apiKeys.getPrimeCustDataFromSap, '');
  const savePrimaryCustomerMasterLoading = apiStatuses.pending === savePrimaryCustomerMasterStatus;
  const getEditPrimaryCustomerMasterData = getData(state, apiKeys.getEditPrimaryCustomerMaster, {});
  const getPrimeCustDataFromSapData = getData(state, apiKeys.getPrimeCustDataFromSap, {});
  const getEditPrimaryCustomerMasterStatus = getStatus(state, 
    apiKeys.getEditPrimaryCustomerMaster, '');
  const getEditPrimaryCustomerMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditPrimaryCustomerMaster,
    formatMessage({ id: 'failed-to-get-selected-primaryCustomer-details' })
  );

  const validationSchema = yup.object({
    customerGroupName: yup.object().required(formatMessage({ id: 'this-field-is-required' })),
    subzoneName: yup.object().required(formatMessage({ id: 'this-field-is-required' })),
    zoneName: yup.object().required(formatMessage({ id: 'this-field-is-required' })),
    cityName: yup.object().required(formatMessage({ id: 'this-field-is-required' })),
    primeCusSapCode: yup.string()
      .required(formatMessage({ id: 'this-field-is-required' }))
      .min(6, formatMessage({ id: 'minimum-6-characters-requirement' }))
      .max(10, formatMessage({ id: 'maximum-10-characters-requirement' }))
      .matches(/^[0-9]+$/, formatMessage({ id: 'only-numeric-characters-allowed' })),
    primeCusName: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    primeCusGstNo: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    address: yup.string().nullable(),
    file: yup.string().nullable(),
    landmark: yup.string().nullable(),
    contactPerson: yup.string().nullable(formatMessage({ id: 'this-field-is-required' })),
    contactNumber: yup.string().nullable(),
    contactWhatsappNo: yup.string().nullable(),
    primeCusEmail: yup.string().nullable(),
    shipToPartyCode: yup.string().nullable(),
    sapZoneName: yup.string().nullable(),
    sapSubZoneName: yup.string().nullable(),
    sapDistrictName: yup.string().nullable(),
    sapPriceGroupName: yup.string().nullable(),
    sapCityName: yup.string().nullable(),
    salesSupportRep: yup.object().required(formatMessage({ id: 'this-field-is-required' })),
    teamLeaderName: yup.object().required(formatMessage({ id: 'this-field-is-required' })),
    salesHeadName: yup.object().required(formatMessage({ id: 'this-field-is-required' })),
    zoneHeadName: yup.object().required(formatMessage({ id: 'this-field-is-required' })),
    salesOrg: yup.string().nullable(formatMessage({ id: 'this-field-is-required' })),
    priceGroupName: yup.object().required(formatMessage({ id: 'this-field-is-required' })),
    sapCustomerGroupName: yup.string().nullable(formatMessage({ id: 'this-field-is-required' })),
    pincode: yup
      .string()
      .required(formatMessage({ id: 'this-field-is-required' }))
      .matches(/^[0-9]+$/, formatMessage({ id: 'only-numerical-characters-allowed' }))
      .length(6, formatMessage({ id: 'exact-6-characters-requirement' })),
    distributionChannel: yup.string().nullable(formatMessage({ id: 'this-field-is-required' })),
    incoterms: yup.string().nullable(formatMessage({ id: 'this-field-is-required' })),
    sapDivision: yup.object().nullable(),
    sapTeamLeaderName: yup.string().nullable(),
    sapZoneHeadName: yup.string().nullable(),
    sapSalesSupportRep: yup.string().nullable(),
    sapSalesHeadName: yup.string().nullable(),
    sapCountry: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    contactNo: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    sapCompany: yup.string().nullable(formatMessage({ id: 'this-field-is-required' })),
    regionCode: yup.string().nullable(formatMessage({ id: 'this-field-is-required' })),
    districtName: yup.object().required(formatMessage({ id: 'this-field-is-required' })),
    latitude: yup.string().nullable(),
    longitude: yup.string().nullable(),
    activeStatus: yup.boolean().required(formatMessage({ id: 'this-field-is-required' }))

  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    // onSubmit: async (values) => { // Make the onSubmit function async
    //   if (Object.keys(formik.errors).length > 0) {
    //     console.log('Validation errors:', formik.errors);
    //     return; // Exit if there are errors
    //   }
    //   if (activeEditId) {
    //     await updatePrimaryCustomerMaster(values);
    //   } else {
    //     await savePrimaryCustomerMaster(values);
    //   }
    // }
  });
  

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const { values } = formik;

  const { districtId, primeCusSapCode, file, zoneId, subzoneId } = values;

  useEffect(() => {
    let payload;
    if (zoneId ) {
      payload = getFormattedPayload({ zoneId });
      getSubZoneList(payload);
    }
  }, [zoneId, getSubZoneList ]);

  useEffect(() => {
    if (subzoneId) {
      const payload = getFormattedPayload({ subzoneId });
      getDistrict(payload);
    }
    getUserList({divisionId:5});
  }, [getDistrict, getUserList, subzoneId]);

  const getPrimeCustDataFromSap = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getPrimeCustDataFromSap(primeCusSapCode),
        method: apiMethods.GET,
        key: apiKeys.getPrimeCustDataFromSap,
        successMsg: formatMessage({ id: 'data-fetched-successfully-from-sap' }),
        failureMsg: formatMessage({ id: 'data-fetch-failed-from-sap' })
      })
    );
  }, [dispatch, formatMessage, primeCusSapCode]);



  const onSearch = useCallback(() => {
    getPrimeCustDataFromSap(primeCusSapCode);
  }, [primeCusSapCode, getPrimeCustDataFromSap]);

  const handleSapCodeBlur = useCallback(() => {
    formik.setFieldTouched('primeCusSapCode', true, true); 
  }, [formik]);
  
  const handleClick = useCallback(() => {
    onSearch(); 
    handleSapCodeBlur(); 
  }, [onSearch, handleSapCodeBlur]);

  const savePrimaryCustomerMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.savePrimaryCustomerMaster,
          method: apiMethods.POST,
          key: apiKeys.savePrimaryCustomerMaster,
          successMsg: formatMessage({ id: 'PrimaryCustomer-master-is-saved-successfully' }),
          failureMsg: formatMessage({ id: 'PrimaryCustomer-master-is-failed-to-save' }),
          body: getFormattedPayload(values)
        })
      );
    },
    [dispatch, formatMessage]
  );

  const updatePrimaryCustomerMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.updatePrimaryCustomerMaster,
          method: apiMethods.PUT,
          key: apiKeys.updatePrimaryCustomerMaster,
          successMsg: formatMessage({ id: 'PrimaryCustomer-master-is-updated-successfully' }),
          failureMsg: formatMessage({ id: 'PrimaryCustomer-master-is-failed-to-update' }),
          body: getFormattedPayload({ ...values, id: activeEditId })
        })
      );
    },
    [dispatch, formatMessage, activeEditId]
  );

  const onChangeZone = useCallback(() => {
    formik.setFieldValue('subzoneId', undefined);
    formik.setFieldValue('districtId', undefined);
    formik.setFieldValue('cityId', undefined);
  }, [formik]);

  const onChangeSubzone = useCallback(() => {
    formik.setFieldValue('districtId', undefined);
    formik.setFieldValue('cityId', undefined);
  }, [formik]);

  const onChangeDistrict = useCallback(() => {
    formik.setFieldValue('cityId', undefined);
  }, [formik]);

  const handleSave = useCallback(async () => {
    if (Object.keys(formik.errors).length === 0) {
      if (activeEditId) {
        await updatePrimaryCustomerMaster(values);
      } else {
        await savePrimaryCustomerMaster(values);
      }
    } else{
      setAlertVisible(true);
    } 
  }, [formik, activeEditId, savePrimaryCustomerMaster, updatePrimaryCustomerMaster, values]);

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]); // Extract base64 part
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadFile = useCallback(
    async (file) => {
      const base64Promises = file.map((file) => readFileAsBase64(file));
      const base64Results = await Promise.all(base64Promises);

      const formData = new FormData();
      base64Results.forEach((base64) => {
        formData.append(`fileName`, base64);
      });
      formData.append('moduleId', '9');
      formData.append(
        'rawFile',
        file.map((file) => file.name)
      );

      const response = await dispatch(
        fetchData({
          url: apiUrls.saveUserImage,
          method: apiMethods.POST,
          key: apiKeys.saveUserImage,
          successMsg: formatMessage({ id: 'user-image-is-saved-successfully' }),
          failureMsg: formatMessage({ id: 'user-image-is-failed-to-save' }),
          body: formData
        })
      );

      if (response.payload && response.payload.response && response.payload.response.data) {
        const imageData = response.payload.response.data;
        setImage(imageData);
      } else {
        console.error('Failed to get image data');
      }
    },
    [dispatch, formatMessage, file]
  );

  const onRemoveCallBack = useCallback(() => {
    const fileName = image[0].imageName;
    dispatch(
      fetchData({
        url: apiUrls.deleteUserImage,
        method: apiMethods.POST,
        key: apiKeys.deleteUserImage,
        successMsg: formatMessage({ id: 'user-image-is-deleted-successfully' }),
        failureMsg: formatMessage({ id: 'user-image-is-deletion-failed' }),
        body: { moduleId: 9, fileName: fileName }
      })
    );
  }, [dispatch, formatMessage, image]);

  useEffect(() => {
    let payload;
    if (districtId) {
      payload = getFormattedPayload({ districtId });
      getCity(payload);
      formik.setFieldValue('designationId', undefined);
    }
  }, [districtId, getCity]);

  const getEditPrimaryCustomerMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditPrimaryCustomerMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditPrimaryCustomerMaster,
        failureMsg: formatMessage({ id: 'failed-to-get-selected-primaryCustomer-details' })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditPrimaryCustomerMaster();
    }
  }, [activeEditId, getEditPrimaryCustomerMaster]);

  useEffect(() => {
    if (getEditPrimaryCustomerMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditPrimaryCustomerMasterData));
    } else if (getPrimeCustDataFromSapStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getPrimeCustDataFromSapData));
    }
  }, [getEditPrimaryCustomerMasterStatus, getPrimeCustDataFromSapStatus]);


  useEffect(() => {
    return () => {
      dispatch(
        clearMultipleData({
          key: [
            apiKeys.divisionMaster,
            apiKeys.getDepartmentOptions,
            apiKeys.savePrimaryCustomerMaster,
            apiKeys.updatePrimaryCustomerMaster,
            apiKeys.getEditPrimaryCustomerMaster,
            apiKeys.getPrimeCustDataFromSap
          ]
        })
      );
    };
  }, [dispatch]);

  return useMemo(
    () => ({
      formik,
      handleClick,
      handleSave,
      values: formik.values,
      savePrimaryCustomerMasterStatus,
      savePrimaryCustomerMasterLoading,
      getEditPrimaryCustomerMasterData,
      getEditPrimaryCustomerMasterStatus,
      getEditPrimaryCustomerMasterErrMsg,
      getPrimeCustDataFromSapStatus,
      uploadFile,
      onChangeZone,
      onChangeSubzone,
      onChangeDistrict,
      alertVisible,
      onRemoveCallBack
    }),
    [
      formik,
      handleClick,
      handleSave,
      savePrimaryCustomerMasterStatus,
      savePrimaryCustomerMasterLoading,
      getEditPrimaryCustomerMasterData,
      getEditPrimaryCustomerMasterStatus,
      getEditPrimaryCustomerMasterErrMsg,
      getPrimeCustDataFromSapStatus,
      uploadFile,
      onChangeZone,
      onChangeSubzone,
      onChangeDistrict,
      alertVisible,
      onRemoveCallBack
    ]
  );
}

export default useCreateEditPrimaryCustomerMaster;
